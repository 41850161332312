import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const mergedProps = {
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
