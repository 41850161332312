import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import HookUtils from '@front/volcanion/utils/hooks'
import Callbacks from './callbacks'
import _ from 'lodash'

const withContainer = Component => props => {
  const [vehicles, searchVehicles] = hooks.useModelSearchList('vehicle', 'get', {
    default_limit: -1,
    enableCount: true,
    initial_filter: {},
    forced_filter: {
      status: ['available', 'in_use', 'unavailable'],
      current_location: { '!': null }
    },
    watch_type: 'result'
  })
  const [jobs, searchJobs] = hooks.useModelSearchList('job', 'get', {
    default_limit: -1,
    enableCount: true,
    initial_filter: {},
    forced_filter: {
      status: { '!': ['canceled', 'complete'] },
    },
    watch_type: 'result'
  })
  const [fleet_ids] = hooks.useGlobalFieldValues('job_search', ['fleet'])
  const [fleets] = hooks.useModel('fleet', fleet_ids)

  const onSubmitJob = useCallback(Callbacks.onSubmitJobHandler(searchJobs, fleets), [searchJobs, HookUtils.getRecordsWatcher(fleets)])
  const onSubmitVehicle = useCallback(Callbacks.onSubmitVehicleHandler(searchVehicles), [searchVehicles])

  const mergedProps = {
    vehicles,
    jobs,
    fleets,
    onSubmitJob,
    onSubmitVehicle
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
