import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { I18n } from '@front/volcanion'
import { Box, Grid } from '@mui/material'

import Callbacks from './callbacks'
import GroupTab from '@abra/panels/Tab/Group'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const VehicleList = props => {
  const { group_id } = hooks.useParams()
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(group_id), [group_id])

  return (
    <Box className='safearea'>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={11} hidden={!group_id}>
          <GroupTab value={3} />
        </Grid>
        <Grid item xs={11} >
          <SearchFrame
            model_name='vehicle'
            populate={['info.model.brand', 'parent_license', 'group']}
            createButtonProps={{ label: I18n.t('vehicle.add') }}
            allowCreate={!!group_id}
          >
            <SearchForm
              submitOnMount
              filterToForm={filterToForm}
              formToFilter={formToFilter}
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable>
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame>
        </Grid>
      </Grid>
    </Box >
  )
}

export default React.memo(VehicleList)
