import FormatUtils from '@front/volcanion/utils/format'
class Callbacks {
  static filterToFormHandler(CODE_INTERNATIONAL) {
    return function filterToForm(filter) {
      if (_.get(filter, 'auth.gsm'))
        _.merge(filter, {
          auth: {
            gsm: FormatUtils.parsePhoneNumber(_.get(filter, 'auth.gsm'), CODE_INTERNATIONAL)
          }
        })
      return {
        status: ['active', 'deleted', 'suspended'],
        ...filter,
      }
    }
  }
  static formToFilterHandler(CODE_INTERNATIONAL) {
    return function formToFilter(values, extra, meta, state) {
      const { status, ...rest } = values || {}
      return _.merge({},
        rest,
        { customerinfo: { '!': null } },
        !_.isEmpty(status) && { auth: FormatUtils.getParseFilterStatus(status) },
        !!rest?.auth?.gsm && { auth: { gsm: FormatUtils.parsePhoneNumber(values.auth.gsm, CODE_INTERNATIONAL) } },
        !!rest?.customerinfo?.name && { customerinfo: { name: { contains: values?.customerinfo?.name } } },
        !!rest?.info?.last_name && { info: { last_name: { startsWith: values?.info?.last_name } } },
        !!rest?.info?.first_name && { info: { first_name: { startsWith: values?.info?.first_name } } },
        !!rest?.auth?.email && { auth: { email: { contains: values?.auth?.email } } },
      )
    }
  }
}

export default Callbacks

