import React from 'react'
import { useApplicationColor } from '@abra/hooks/application'

import { Typography, Link } from '@mui/material'
import { TableCell } from '@mui/material'
import { TextCell, StatusCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row }) => <>
  <TableCell size={'small'}>
    <Typography
      sx={{
        color: useApplicationColor(_.get(row, 'auth.application')),
        fontWeight: 800,
        fontSize: 'subtitle1.fontSize',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Link sx={{ color: 'inherit' }}
        target='_blank'
        onClick={(e) => {
          window.open(`/client/${_.get(row, 'user_id')}`, '_blank')
          e.stopPropagation()
        }}
      >
        {_.get(row, 'customerinfo.client_number')}
      </Link>
    </Typography>
  </TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'customerinfo.name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'info.first_name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'info.last_name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'auth.email')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'auth.gsm')} /></TableCell>
  <TableCell size={'small'}><StatusCell value={_.get(row, 'auth')} /></TableCell>
</>

export default React.memo(Row)
