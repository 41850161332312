import React from 'react'
import { I18n, Form } from '@front/volcanion'

import { Box, Divider, Grid, Collapse, Button } from '@mui/material'
import { ModelMenuSelectorField } from '@front/squirtle'

const VehicleForm = ({
  disable_options,
  options_watchers,
  getOptionsFilter,
  onSubmit,
  requestSubmit,
  ...props
}) =>
  <Form
    name={'vehicle_search'}
    initialState={{ isReadOnly: false, isReady: true }}
    initialValues={{}}
    status={{ isReady: true }}
    onSubmit={onSubmit}
    closeOnSuccess={false}
    submitOnEmpty
    submitChangesOnly={false}
  >
    <Box sx={{ width: 280, p: 2, ml: 4, bgcolor: 'background.default', borderRadius: 1, boxShadow: 6 }}>
      <Grid container justifyContent={'center'} spacing={1} rowSpacing={!!disable_options ? 0 : 1}>
        <Grid item xs={12}>
          <Collapse in={!disable_options}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <ModelMenuSelectorField
                  name={'user_options'}
                  label={'Options Chauffeurs'}
                  model_name='useroption'
                  searchMode='search'
                  labelKeys={['name_translated']}
                  loadOnMount
                  multiple
                  watchers={options_watchers}
                  autocompleteMutator={getOptionsFilter}
                />
              </Grid>
              <Grid item xs={12}>
                <ModelMenuSelectorField
                  name={'vehicle_options'}
                  label={'Options Vehicules'}
                  model_name='vehicleoption'
                  searchMode='search'
                  labelKeys={['name_translated']}
                  loadOnMount
                  multiple
                  watchers={options_watchers}
                  autocompleteMutator={getOptionsFilter}
                />
              </Grid>
              <Grid item xs={12}>
                <Button onClick={requestSubmit} sx={{ p: 0, color: 'primary.main' }}> Submit </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Box >
  </Form>

export default React.memo(VehicleForm)
