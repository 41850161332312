import React from 'react'
import { I18n, Form } from '@front/volcanion'

import { Box, Divider, Grid, Typography, Button } from '@mui/material'
import { ModelOptionSelectorField, ModelMenuSelectorField, SingleOptionSelectorField } from '@front/squirtle'


const JobForm = ({
  cp_watchers,
  getCommercialPackageFilter,
  fleetChange,
  cpChange,
  switchChange,
  onSubmit,
  requestSubmit,
  ...props
}) => <>
    <Form
      name={'job_search'}
      initialState={{ isReadOnly: false, isReady: true }}
      initialValues={{}}
      status={{ isReady: true }}
      onSubmit={onSubmit}
      closeOnSuccess={false}
      submitOnEmpty
      submitChangesOnly={false}
    >
      <Box sx={{ width: 280, p: 2, ml: 4, bgcolor: 'background.default', borderRadius: 1, boxShadow: 6 }}>
        <Grid container justifyContent={'center'} spacing={1} rowSpacing={2}>
          <Grid item xs={12}>
            <ModelOptionSelectorField
              name={'fleet'}
              label={'Flotte:'}
              model_name='fleet'
              searchMode='search'
              labelKeys={['name']}
              afterChange={fleetChange}
              config={{ forced_filter: { display_monitoring: true } }}
              getOptionLabelProps={() => ({
                componentsProps: { typography: { sx: { fontSize: 13, mt: '3px' } } },
                labelPlacement: 'end'
              })}
              multiple
              loadOnMount
            />
          </Grid>
          <Grid item xs={12}>
            <ModelMenuSelectorField
              name={'commercial_package'}
              label={'Commercial Packages'}
              model_name='commercialpackage'
              searchMode='search'
              labelKeys={['name_translated']}
              loadOnMount
              config={{ forced_filter: { display_supervision: true } }}
              multiple
              watchers={cp_watchers}
              autocompleteMutator={getCommercialPackageFilter}
              afterChange={cpChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SingleOptionSelectorField
              name={'cp_option'}
              optionLabel={'Utiliser les options des packages'}
              afterChange={switchChange}
              valueType='checkbox'
              getOptionLabelProps={() => ({
                componentsProps: { typography: { sx: { fontSize: 13, mt: '3px' } } },
                labelPlacement: 'end'
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={requestSubmit} sx={{ p: 0, color: 'primary.main' }}> Submit </Button>
          </Grid>
        </Grid>
      </Box>
    </Form>
  </>

export default React.memo(JobForm)
