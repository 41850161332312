import _ from "lodash"

class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        status: ['active', 'deleted', 'suspended'],
        ...filter
      }
    }
  }

  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      return {
        ...values,
        staffinfo: { '!': null },
        status: _.map(values?.status, name => ({ name })),
      }
    }
  }
}

export default Callbacks
