import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {

  const [zones, searchZones, , { clear: clearZones }] = hooks.useModelSearch('zone', 'get', {
    initial_filter: {}
  })

  useEffect(() => {
    return () => clearZones()
  }, [])

  const mergedProps = {
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
